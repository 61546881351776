<template>
  <div class="client-page flex-column bg-table-header">
    <div class="p-tb-10 p-l-10 border-bottom-1 flex bg-white">
      <span class="cursor-pointer" @click="toBack">
        <i class="el-icon-arrow-left"></i>
        返回
      </span>
      <div class="flex1 text-center font-size-16 font-weight-bold">修改订单</div>
    </div>
    <div class="flex1 p-lr-20 p-tb-20 flex-column overflow-auto">
      <!-- 开单类型 -->
      <div class="m-t-10 bg-white  p-tb-20 p-lr-20 font-size-20 font-weight-bold">
        <div class="flex flex-between">
          <div class="m-b-10">
            <span>订单号：{{ orderInfo.orderCode }}</span>
          </div>
          <div>
            <span class="m-r-20">提交人：{{ orderInfo.submitByName }} </span>
            <span>提交时间：{{ orderInfo.submitAt }}</span>
          </div>
        </div>
        <div>
          <span class="m-r-20" v-show="false">开单类型：代他人开单 </span>
          <span v-if="orderInfo.buyerName">买家名称：{{ orderInfo.buyerName }}</span>
        </div>
      </div>

      <!-- 收货地址 -->
      <Card>
        <template #title>收货地址</template>
        <template #content>
          <div>
            <EditAddress @select="selectAddress" :addressInfo="addressInfo" />
          </div>
        </template>
      </Card>

      <!-- 商品明细 -->
      <Card>
        <template #title>商品明细</template>
        <template #content>
          <OrderDetailItem class="detail" :table="goodsDetailsTable" :isShow="false" isEdit @change="tableDataChange">
            <template #footer>
              <div>
                <span>共{{ goodsDetailsTable.length }}种，{{ totalQty }}件商品</span>
                <span class="m-l-20">合计：¥{{ totalAmount }}</span>
              </div>
            </template>
          </OrderDetailItem>
        </template>
      </Card>
      <!-- 订单信息 -->
      <Card>
        <template #title>订单信息</template>
        <template #content>
          <div class="bg-white orderInfo  flex">
            <div class="border-right-1 flex1">
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">结算方式：</span>
                <span class="text-666">{{ orderInfo.paymentType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">发票类型：</span>
                <span class="text-666">{{ orderInfo.invoiceType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">提货方式：</span>
                <span class="text-666">{{ orderInfo.deliveryType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">运输方式：</span>
                <span class="text-666">{{ orderInfo.transportType }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">创建人：</span>
                <span class="text-666">{{ orderInfo.createdByName }}</span>
              </div>
              <div class="m-b-10">
                <span class="w-100 font-weight-bold">创建时间：</span>
                <span class="text-666">{{ orderInfo.createdAt }}</span>
              </div>
            </div>
            <div class="flex1 m-l-20">
              <div class="font-weight-bold">买家备注：</div>
              <el-input
                type="textarea"
                class="m-t-4"
                placeholder="请输入内容"
                v-model="orderInfo.remarks"
                maxlength="200"
                clearable
                show-word-limit
                :autosize="{ minRows: 5, maxRows: 5 }"
              >
              </el-input>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="bg-white p-tb-10 p-lr-10 text-right border-top-1">
      <el-button type="primary" size="small" @click="sureFn">
        确定(F4)
      </el-button>
    </div>
  </div>
</template>

<script>
import Card from "../components/Card.vue";
import EditAddress from "../components/EditAddress.vue";
import OrderDetailItem from "../components/OrderDetailItem.vue";

import { getOrderDetail, updateOrders } from "@/api/order";

export default {
  name: "modifyOrderDetail", //待提交 修改订单详情
  components: {
    Card,
    EditAddress,
    OrderDetailItem
  },
  data() {
    return {
      keyName: "f4",
      keyScope: "ModifyOrderDetail",
      // 订单详情
      orderInfo: {},
      addressInfo: {},
      // 商品明细表
      goodsDetailsTable: []
    };
  },
  computed: {
    // 总件数
    totalQty() {
      let count = 0;
      this.goodsDetailsTable.forEach(p => {
        count += Number(p.qty);
      });
      return count;
    },
    // 总金额
    totalAmount() {
      let amount = 0;
      this.goodsDetailsTable.forEach(p => {
        amount += Number(p.qty) * Number(p.price);
      });
      return amount;
    }
  },
  mounted() {
    this.getOrderDetailFn();

    setTimeout(() => {
      this.$hotkeys(this.keyName, this.keyScope, this.handleKeyCode);
      this.$hotkeys.setScope(this.keyScope);
    }, 500);
  },
  beforeDestroy() {
    this.$hotkeys.unbind(this.keyName, this.keyScope);
  },
  methods: {
    handleKeyCode(e) {
      switch (e.code) {
        case "F4":
          e.preventDefault();
          e.stopPropagation();
          this.sureFn();
          break;
      }
    },
    //获取订单详情
    getOrderDetailFn() {
      getOrderDetail({ id: this.$route.query.id })
        .then(res => {
          this.orderInfo = res || {};
          this.addressInfo = {
            type: "modify",
            receiver: res.receiver,
            receiverAddress: res.receiverAddress,
            receiverAreaId: res.receiverAreaId,
            receiverPhone: res.receiverPhone,
            receiverAreaName: res.receiverAreaName
          };
          this.goodsDetailsTable =
            res.details.map(item => ({
              ...item,
              amount: (Number(item.qty) * Number(item.price)).toFixed(2)
            })) || [];
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    //返回
    toBack() {
      this.$router.go(-1);
    },
    //选择地址
    selectAddress(data) {
      this.orderInfo.addressId = data.id;
    },
    tableDataChange(rows) {
      this.goodsDetailsTable = JSON.parse(JSON.stringify(rows));
    },
    //确定修改
    sureFn() {
      const params = {
        ...this.orderInfo,
        details: this.goodsDetailsTable
      };
      updateOrders(params)
        .then(() => {
          this.$message.success("订单修改成功");
          this.$router.go(-1);
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.orderInfo {
  span {
    display: inline-block;
  }
}
.detail {
  max-height: 400px;
}
</style>
